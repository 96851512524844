import { FormApi, ValidationErrors } from 'final-form';
import * as React from 'react';
import { Field, Form, FormRenderProps } from 'react-final-form';
import FontAwesomeIcon from 'react-fontawesome';
import { connect } from 'react-redux';
import { enqueueNotification } from './actions/Actions';
import { BASE_URL, CLOUDINARY_BASE_URL } from './Constants';
import { INotification } from './types';

interface FormValues {
    name: string;
    email: string;
}

interface DispatchProps {
    onEnqueueNotification: (notification: INotification) => void;
}

interface State {
    loading: boolean;
}

class App extends React.Component<DispatchProps, State> {
    state: State = {
        loading: false
    };

    render() {
        return (
            <div className='body'>
                <div className='image-wrapper'>
                    <img
                        className='synthesis'
                        src={`${CLOUDINARY_BASE_URL}/generic/web_main/background_synthesis.png`}
                    />
                    <Form
                        render={this.renderForm}
                        onSubmit={this.handleSubmit}
                        validate={this.validateForm}
                    />
                    <img
                        src={`${CLOUDINARY_BASE_URL}/generic/web_main/powered_by_personalized.png`}
                    />
                </div>
            </div>
        );
    }

    private renderForm = ({
        handleSubmit,
        validating,
        submitting
    }: FormRenderProps) => {
        const { loading } = this.state;

        return (
            <form onSubmit={handleSubmit} className='newsletter-form'>
                <div className='title'>Keep me informed about Scan & Order</div>
                <Field name='email'>
                    {({ input, meta }) => (
                        <div className='form-field'>
                            <div className='input-wrapper'>
                                <FontAwesomeIcon name='envelope' className='icon' />
                                <input {...input} placeholder='Email' type='email' />
                            </div>
                            {meta.error && meta.touched && (
                                <span className='error'>{meta.error}</span>
                            )}
                        </div>
                    )}
                </Field>
                <Field name='name'>
                    {({ input, meta }) => (
                        <div className='form-field'>
                            <div className='input-wrapper'>
                                <FontAwesomeIcon name='user' className='icon' />
                                <input {...input} placeholder='Name' type='text' />
                            </div>
                            {meta.error && meta.touched && (
                                <span className='error'>{meta.error}</span>
                            )}
                        </div>
                    )}
                </Field>
                <button
                    type='submit'
                    className='subscribe'
                    disabled={loading || validating || submitting}>
                    Subscribe
                </button>
            </form>
        );
    };

    private validateForm = (values: FormValues): ValidationErrors => {
        const errors: ValidationErrors = {};

        if (!values.name) {
            errors.name = 'Required field';
        }
        if (!values.email) {
            errors.email = 'Required field';
        } else if (!/\S+@\S+\.\S+/.test(values.email)) {
            errors.email = 'Not a valid email address';
        }

        return errors;
    };

    private handleSubmit = (values: FormValues, form: FormApi) => {
        this.setState({ loading: true });

        fetch(`${BASE_URL}/Newsletters/AddNew`, {
            method: 'POST',
            body: JSON.stringify({
                ...values,
                secret: 'Sc@n@nd=rd#rN#sl#tt#r'
            }),
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(x => x.text())
            .then(() => {
                form.reset();

                Object.keys(values).forEach(key => {
                    form.resetFieldState(key);
                });

                const { onEnqueueNotification } = this.props;
                onEnqueueNotification({
                    key: new Date().getTime(),
                    message: 'Your subscription was successful',
                    options: {
                        variant: 'success'
                    }
                });
            })
            .finally(() => {
                this.setState({ loading: false });
            });
    };
}

export default connect<{}, DispatchProps>(
    null,
    (dispatch): DispatchProps => ({
        onEnqueueNotification: notification =>
            dispatch<any>(enqueueNotification(notification))
    })
)(App);
