import * as React from 'react';
import { CLOUDINARY_BASE_URL } from './Constants';

const Wrapper: React.FC<{}> = ({ children }) => (
    <div className='wrapper'>
        <div className='header' />
        <div className='wrapper-body'>
            <img
                src={`${CLOUDINARY_BASE_URL}/backoffice/powered_by_personalized.png`}
            />
            {children}
        </div>
        <div className='footer'>
            <img
                src={`${CLOUDINARY_BASE_URL}/generic/web_main/powered_by_personalized.png`}
            />
        </div>
    </div>
);

export default Wrapper;
