import { Action, ActionType } from '../actions/actionTypes';
import STORE from '../store';
import { AppStore, Mode } from '../types';

const reducer = (state = STORE.app, action: Action): AppStore => {
    switch (action.type) {
        case ActionType.REMOVE_NOTIFICATION:
            return {
                ...state,
                notifications: state.notifications.filter(
                    ({ key }) => key !== action.payload
                )
            };
        case ActionType.ENQUEUE_NOTIFICATION:
            return {
                ...state,
                notifications: state.notifications.concat(action.payload)
            };
        case ActionType.SET_SELECTED_LICENSE_ID:
            return { ...state, selectedLicenseId: action.payload };
        case ActionType.SET_LICENSES:
            return { ...state, licenses: action.payload };
        case ActionType.TOGGLE_MODE:
            return {
                ...state,
                licenseMode:
                    state.licenseMode === Mode.MONTHLY ? Mode.YEARLY : Mode.MONTHLY
            };
        default:
            return state;
    }
};

export default reducer;
