import * as React from 'react';
import FontAwesomeIcon from 'react-fontawesome';
import { Field, Form, FormRenderProps } from 'react-final-form';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { FORM_ERROR, ValidationErrors } from 'final-form';
import {
    Card,
    CardContent,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow
} from '@mui/material';
import { enqueueNotification } from './actions/Actions';
import { AppStore, INotification, Mode, Store } from './types';
import Wrapper from './Wrapper';
import * as Api from './Api';
import { CLOUDINARY_BASE_URL } from './Constants';

interface StateProps {
    selectedLicenseId: AppStore['selectedLicenseId'];
    mode: AppStore['licenseMode'];
}

interface DispatchProps {
    onEnqueueNotification: (notification: INotification) => void;
}

interface FormValues {
    token: string;
    username: string;
    password: string;
    clientEmail: string;
    clientName: string;
    storeName: string;
    storeAddress: string;
    clientAFM: string;
    clientDOY: string;
    clientPhone: string;
    defaultUILanguageID: string;
}

interface Language {}

type Props = StateProps & DispatchProps;

const REQUIRED = 'Required field';

class Registration extends React.Component<
    Props & RouteComponentProps,
    { loading: boolean; languages: Language[] }
> {
    private componentMounted: boolean = false;

    state = {
        loading: false,
        languages: [
            { name: 'Ελληνικά', code: 'el', id: 1 },
            { name: 'English', code: 'en', id: 2 },
            { name: 'Deutsch', code: 'de', id: 3 }
        ]
    };

    render() {
        const { selectedLicenseId } = this.props;

        if (selectedLicenseId === null) {
            return null;
        }

        return (
            <Wrapper>
                <div className='registration-form'>
                    <Form
                        render={this.renderForm}
                        validate={this.validateForm}
                        onSubmit={this.handleRegister}
                        initialValues={{ defaultUILanguageID: '1' }}
                    />
                </div>
            </Wrapper>
        );
    }

    componentDidMount() {
        const { selectedLicenseId, history } = this.props;

        this.componentMounted = true;

        if (selectedLicenseId === null) {
            history.push('/pricing');
        }
    }

    componentWillUnmount() {
        this.componentMounted = false;
    }

    private renderForm = ({
        submitting,
        validating,
        handleSubmit,
        submitErrors
    }: FormRenderProps) => {
        const { loading, languages } = this.state;

        return (
            <form onSubmit={handleSubmit}>
                <Card className='card'>
                    <CardContent className='form'>
                        <TableContainer>
                            <Table>
                                <TableBody>
                                    <TableRow>
                                        <TableCell className='label'>Token</TableCell>
                                        <TableCell>
                                            <Field name='token'>
                                                {({ input, meta }) => (
                                                    <div className='field-input'>
                                                        <input
                                                            {...input}
                                                            type='password'
                                                            autoComplete='new-password'
                                                        />
                                                        {meta.error && meta.touched && (
                                                            <FontAwesomeIcon
                                                                className='error-icon'
                                                                name='exclamation-circle'
                                                                title={meta.error}
                                                            />
                                                        )}
                                                    </div>
                                                )}
                                            </Field>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className='label'>
                                            Username
                                        </TableCell>
                                        <TableCell>
                                            <Field name='username'>
                                                {({ input, meta }) => (
                                                    <div className='field-input'>
                                                        <input {...input} type='text' />
                                                        {meta.error && meta.touched && (
                                                            <FontAwesomeIcon
                                                                className='error-icon'
                                                                name='exclamation-circle'
                                                                title={meta.error}
                                                            />
                                                        )}
                                                    </div>
                                                )}
                                            </Field>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className='label'>
                                            Password
                                        </TableCell>
                                        <TableCell>
                                            <Field name='password'>
                                                {({ input, meta }) => (
                                                    <div className='field-input'>
                                                        <input
                                                            {...input}
                                                            type='password'
                                                            autoComplete='new-password'
                                                        />
                                                        {meta.error && meta.touched && (
                                                            <FontAwesomeIcon
                                                                className='error-icon'
                                                                name='exclamation-circle'
                                                                title={meta.error}
                                                            />
                                                        )}
                                                    </div>
                                                )}
                                            </Field>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className='label'>Email</TableCell>
                                        <TableCell>
                                            <Field name='clientEmail'>
                                                {({ input, meta }) => (
                                                    <div className='field-input'>
                                                        <input
                                                            {...input}
                                                            type='email'
                                                        />
                                                        {meta.error && meta.touched && (
                                                            <FontAwesomeIcon
                                                                className='error-icon'
                                                                name='exclamation-circle'
                                                                title={meta.error}
                                                            />
                                                        )}
                                                    </div>
                                                )}
                                            </Field>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className='label'>Name</TableCell>
                                        <TableCell>
                                            <Field name='clientName'>
                                                {({ input, meta }) => (
                                                    <div className='field-input'>
                                                        <input {...input} type='text' />
                                                        {meta.error && meta.touched && (
                                                            <FontAwesomeIcon
                                                                className='error-icon'
                                                                name='exclamation-circle'
                                                                title={meta.error}
                                                            />
                                                        )}
                                                    </div>
                                                )}
                                            </Field>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className='label'>
                                            Store name
                                        </TableCell>
                                        <TableCell>
                                            <Field name='storeName'>
                                                {({ input, meta }) => (
                                                    <div className='field-input'>
                                                        <input {...input} type='text' />
                                                        {meta.error && meta.touched && (
                                                            <FontAwesomeIcon
                                                                className='error-icon'
                                                                name='exclamation-circle'
                                                                title={meta.error}
                                                            />
                                                        )}
                                                    </div>
                                                )}
                                            </Field>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className='label'>
                                            Store address
                                        </TableCell>
                                        <TableCell>
                                            <Field name='storeAddress'>
                                                {({ input, meta }) => (
                                                    <div className='field-input'>
                                                        <input {...input} type='text' />
                                                        {meta.error && meta.touched && (
                                                            <FontAwesomeIcon
                                                                className='error-icon'
                                                                name='exclamation-circle'
                                                                title={meta.error}
                                                            />
                                                        )}
                                                    </div>
                                                )}
                                            </Field>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className='label'>AFM</TableCell>
                                        <TableCell>
                                            <Field name='clientAFM'>
                                                {({ input, meta }) => (
                                                    <div className='field-input'>
                                                        <input {...input} type='text' />
                                                        {meta.error && meta.touched && (
                                                            <FontAwesomeIcon
                                                                className='error-icon'
                                                                name='exclamation-circle'
                                                                title={meta.error}
                                                            />
                                                        )}
                                                    </div>
                                                )}
                                            </Field>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className='label'>DOY</TableCell>
                                        <TableCell>
                                            <Field name='clientDOY'>
                                                {({ input, meta }) => (
                                                    <div className='field-input'>
                                                        <input {...input} type='text' />
                                                        {meta.error && meta.touched && (
                                                            <FontAwesomeIcon
                                                                className='error-icon'
                                                                name='exclamation-circle'
                                                                title={meta.error}
                                                            />
                                                        )}
                                                    </div>
                                                )}
                                            </Field>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className='label'>
                                            Phone number
                                        </TableCell>
                                        <TableCell>
                                            <Field name='clientPhone'>
                                                {({ input, meta }) => (
                                                    <div className='field-input'>
                                                        <input {...input} type='tel' />
                                                        {meta.error && meta.touched && (
                                                            <FontAwesomeIcon
                                                                className='error-icon'
                                                                name='exclamation-circle'
                                                                title={meta.error}
                                                            />
                                                        )}
                                                    </div>
                                                )}
                                            </Field>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className='label'>
                                            Default language
                                        </TableCell>
                                        <TableCell>
                                            {languages.map(({ name, id, code }) => (
                                                <div
                                                    key={id}
                                                    className='language-radio'>
                                                    <Field
                                                        name='defaultUILanguageID'
                                                        component='input'
                                                        type='radio'
                                                        value={id.toString()}
                                                        id={id.toString()}
                                                    />
                                                    <label htmlFor={id.toString()}>
                                                        <img
                                                            src={`${CLOUDINARY_BASE_URL}/generic/languages/${code}.png`}
                                                        />
                                                        {name}
                                                    </label>
                                                </div>
                                            ))}
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <div className='submit-wrapper'>
                            <button type='submit' disabled={submitting || validating}>
                                Register
                            </button>
                            {loading && (
                                <FontAwesomeIcon name='spinner' className='fa-spin' />
                            )}
                        </div>
                        {this.renderSubmitErrors(submitErrors)}
                    </CardContent>
                </Card>
            </form>
        );
    };

    private renderSubmitErrors(errors: Record<string, string[]>) {
        return (
            <div className='submit-errors'>
                {Object.keys(errors || {}).map(key => (
                    <React.Fragment key={key}>
                        {errors[key].map((err, i) => (
                            <div className='error' key={i}>
                                {err}
                            </div>
                        ))}
                    </React.Fragment>
                ))}
            </div>
        );
    }

    private validateForm = (values: FormValues): ValidationErrors => {
        const errors: ValidationErrors = {};

        [
            'token',
            'username',
            'password',
            'clientEmail',
            'clientName',
            'storeName',
            'storeAddress',
            'clientAFM',
            'clientDOY',
            'clientPhone'
        ].forEach(key => {
            if (!values[key]) {
                errors[key] = REQUIRED;
            }

            if (
                key === 'clientEmail' &&
                values[key] &&
                !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(values[key])
            ) {
                errors[key] = 'Invalid email';
            }

            if (key === 'username' && values[key] && !/^\S*$/.test(values[key])) {
                errors[key] = 'Cannot contain spaces';
            }
        });

        return errors;
    };

    private handleRegister = (values: FormValues) => {
        const { selectedLicenseId, mode } = this.props;

        this.setState({ loading: true });

        return Api.register({
            ...values,
            licenceID: selectedLicenseId,
            paysMonthly: mode === Mode.MONTHLY,
            defaultUILanguageID: Number(values.defaultUILanguageID),
            defaultMenuLanguageID: Number(values.defaultUILanguageID)
        }).then(res => {
            if (!this.componentMounted) {
                return null;
            }

            this.setState({ loading: false });

            if (Array.isArray(res)) {
                return {
                    [FORM_ERROR]: res
                };
            }

            const { onEnqueueNotification, history } = this.props;

            onEnqueueNotification({
                options: {
                    variant: 'success',
                    autoHideDuration: 5000
                },
                key: new Date().getTime(),
                message: 'Please check your email to confirm your registration'
            });

            history.push('/pricing');

            return null;
        });
    };
}

const mapStateToProps = (state: Store): StateProps => ({
    selectedLicenseId: state.app.selectedLicenseId,
    mode: state.app.licenseMode
});

const mapDispatchToProps = (dispatch): DispatchProps => ({
    onEnqueueNotification: notification => dispatch(enqueueNotification(notification))
});

export default connect<StateProps, DispatchProps>(
    mapStateToProps,
    mapDispatchToProps
)(Registration);
