import * as React from 'react';
import * as _ from 'lodash';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import FontAwesome from 'react-fontawesome';
import {
    Stack,
    Switch,
    SwitchProps,
    Typography,
    styled,
    Card,
    CardContent
} from '@mui/material';
import { AppStore, License, Mode, Store } from './types';
import { setLicenses, setSelectedLicenseId, toggleMode } from './actions/Actions';
import * as Api from './Api';
import Wrapper from './Wrapper';

interface StateProps {
    licenses: AppStore['licenses'];
    selectedLicenseId: AppStore['selectedLicenseId'];
    mode: AppStore['licenseMode'];
}

interface DispatchProps {
    onLicenseSelect: (id: number) => void;
    onSetLicenses: (licenses: License[]) => void;
    onToggleMode: () => void;
}

type Props = StateProps & DispatchProps & RouteComponentProps;

const Toggle = styled((props: SwitchProps) => (
    <Switch focusVisibleClassName='.Mui-focusVisible' disableRipple {...props} />
))(({ theme }) => ({
    width: 60,
    height: 40,
    '& .MuiSwitch-switchBase': {
        padding: 10,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            '& + .MuiSwitch-track': {
                backgroundColor: '#94a3d7',
                opacity: 1,
                border: 0
            },
            '& .MuiSwitch-thumb': {
                backgroundColor: '#445aa8',
                opacity: 1,
                border: 0
            }
        },
        '& .MuiSwitch-thumb': {
            backgroundColor: '#d5185e',
            opacity: 1,
            border: 0
        }
    },
    '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: '#fb85b1',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 300
        })
    }
}));

const COLORS = ['#ed1063', '#4459a7', '#00a9ba', '#f68c1e', '#9652a0'];

class Licenses extends React.Component<Props> {
    render() {
        const { licenses, mode, onToggleMode } = this.props;

        return (
            <Wrapper>
                <div className='pricing'>
                    <Stack
                        direction='row'
                        spacing={2}
                        alignItems='center'
                        className='toggle'>
                        <Typography className='mode monthly'>Monthly</Typography>
                        <Toggle
                            checked={mode === Mode.YEARLY}
                            onChange={onToggleMode}
                        />
                        <Typography className='mode yearly'>Yearly</Typography>
                    </Stack>
                    <div className='licenses'>
                        {licenses.map(
                            (
                                {
                                    id,
                                    name,
                                    supportedItemsForWeb,
                                    monthlyPrice,
                                    description
                                },
                                idx
                            ) => (
                                <Card key={id} className='card'>
                                    <CardContent
                                        style={{ display: 'flex', padding: 7 }}>
                                        <div className='license'>
                                            <div
                                                className='header-color'
                                                style={{
                                                    backgroundColor: COLORS[idx]
                                                }}>
                                                {idx + 1}
                                            </div>
                                            <div className='circles'>
                                                {_.range(3).map(k => (
                                                    <div
                                                        key={k}
                                                        style={{
                                                            backgroundColor: COLORS[idx]
                                                        }}
                                                    />
                                                ))}
                                            </div>
                                            <div className='content'>
                                                <div
                                                    className='name'
                                                    style={{ color: COLORS[idx] }}>
                                                    {name}
                                                </div>
                                                <div className='price'>
                                                    {this.calculatePrice(monthlyPrice)}
                                                    &euro; /{' '}
                                                    {mode === Mode.MONTHLY
                                                        ? 'month'
                                                        : 'year'}
                                                    <div className='yearly-license'>
                                                        {mode === Mode.YEARLY &&
                                                            '(2 months free)'}
                                                    </div>
                                                </div>
                                                <div className='description'>
                                                    {description}
                                                </div>
                                                <div className='separators'>
                                                    {_.range(3).map(k => (
                                                        <div
                                                            key={k}
                                                            style={{
                                                                border: `2px solid ${COLORS[idx]}`
                                                            }}
                                                        />
                                                    ))}
                                                </div>
                                                <div className='items'>
                                                    {supportedItemsForWeb.map(
                                                        (item, index) => (
                                                            <div
                                                                className='item'
                                                                key={index}>
                                                                <FontAwesome
                                                                    name='check'
                                                                    className='check'
                                                                />
                                                                {item}
                                                            </div>
                                                        )
                                                    )}
                                                </div>
                                            </div>
                                            <div className='register'>
                                                <button
                                                    type='button'
                                                    onClick={this.handleLicenseSelect(
                                                        id
                                                    )}>
                                                    Register
                                                </button>
                                            </div>
                                        </div>
                                    </CardContent>
                                </Card>
                            )
                        )}
                    </div>
                </div>
            </Wrapper>
        );
    }

    componentDidMount() {
        const { onSetLicenses, licenses } = this.props;

        if (licenses.length) {
            return;
        }

        Api.getLicenses().then(l => {
            onSetLicenses(l);
        });
    }

    private calculatePrice(monthlyPrice: number) {
        const { mode } = this.props;
        const price = mode === Mode.MONTHLY ? monthlyPrice : monthlyPrice * 10;
        return Math.trunc(price);
    }

    private handleLicenseSelect = (id: number) => () => {
        const { onLicenseSelect, history } = this.props;
        onLicenseSelect(id);
        history.push('/registration');
    };
}

const mapStateToProps = (state: Store): StateProps => ({
    licenses: state.app.licenses,
    selectedLicenseId: state.app.selectedLicenseId,
    mode: state.app.licenseMode
});

const mapDispatchToProps = (dispatch): DispatchProps => ({
    onLicenseSelect: id => dispatch(setSelectedLicenseId(id)),
    onSetLicenses: licenses => dispatch(setLicenses(licenses)),
    onToggleMode: () => dispatch(toggleMode())
});

export default connect<StateProps, DispatchProps>(
    mapStateToProps,
    mapDispatchToProps
)(Licenses);
