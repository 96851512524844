import { ThunkAction as ThunkActionCreator } from 'redux-thunk';
import { AnyAction } from 'redux';
import { SnackbarKey } from 'notistack';
import { Store, INotification, AppStore } from '../types';

export enum ActionType {
    REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION',
    ENQUEUE_NOTIFICATION = 'ENQUEUE_NOTIFICATION',
    SET_SELECTED_LICENSE_ID = 'SET_SELECTED_LICENSE_ID',
    SET_LICENSES = 'SET_LICENSES',
    TOGGLE_MODE = 'TOGGLE_MODE'
}

interface CloseNotification {
    type: ActionType.REMOVE_NOTIFICATION;
    payload: SnackbarKey;
}

interface EnqueueNotification {
    type: ActionType.ENQUEUE_NOTIFICATION;
    payload: INotification;
}

interface SetSelectedLicenseId {
    type: ActionType.SET_SELECTED_LICENSE_ID;
    payload: AppStore['selectedLicenseId'];
}

interface SetLicenses {
    type: ActionType.SET_LICENSES;
    payload: AppStore['licenses'];
}

interface ToggleMode {
    type: ActionType.TOGGLE_MODE;
}

export type Action =
    | CloseNotification
    | EnqueueNotification
    | SetSelectedLicenseId
    | SetLicenses
    | ToggleMode;

export type ThunkAction<R = void> = ThunkActionCreator<R, Store, unknown, AnyAction>;
