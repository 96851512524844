import { RouterState } from 'connected-react-router';
import { OptionsObject, SnackbarKey, SnackbarMessage } from 'notistack';

export interface Store {
    router?: RouterState;
    app: AppStore;
}

export interface INotification {
    message: SnackbarMessage;
    options: OptionsObject;
    key: SnackbarKey;
}

export interface License {
    id: number;
    name: string;
    monthlyPrice: number;
    description: string;
    supportedItemsForWeb: string[];
}

export enum Mode {
    MONTHLY,
    YEARLY
}

export interface AppStore {
    notifications: INotification[];
    selectedLicenseId: number;
    licenses: License[];
    licenseMode: Mode;
}
