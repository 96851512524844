import { SnackbarKey } from 'notistack';
import { Action, ActionType } from './actionTypes';
import { AppStore, INotification } from '../types';

export const removeNotification = (key: SnackbarKey): Action => ({
    type: ActionType.REMOVE_NOTIFICATION,
    payload: key
});

export const enqueueNotification = (notification: INotification): Action => ({
    type: ActionType.ENQUEUE_NOTIFICATION,
    payload: notification
});

export const setSelectedLicenseId = (id: AppStore['selectedLicenseId']): Action => ({
    type: ActionType.SET_SELECTED_LICENSE_ID,
    payload: id
});

export const setLicenses = (licenses: AppStore['licenses']): Action => ({
    type: ActionType.SET_LICENSES,
    payload: licenses
});

export const toggleMode = (): Action => ({
    type: ActionType.TOGGLE_MODE
});
