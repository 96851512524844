import { License } from './types';

const BASE_URL = 'https://backbonedev.scanandorder.eu/api';

export const getLicenses = (): Promise<License[]> =>
    fetch(`${BASE_URL}/Licences`).then(res => res.json());

export const register = values =>
    fetch(`${BASE_URL}/registration/RegisterNewStore`, {
        method: 'POST',
        body: JSON.stringify(values),
        headers: {
            'Content-Type': 'application/json'
        }
    }).then(res => (res.status === 200 ? res.text() : res.json()));
