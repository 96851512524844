import { Mode, Store } from './types';

const STORE: Store = {
    app: {
        notifications: [],
        selectedLicenseId: null,
        licenses: [],
        licenseMode: Mode.MONTHLY
    }
};

export default STORE;
